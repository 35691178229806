<!-- 登录页面 -->
<template>
  <div class="wrap">
    <!-- 上面logo -->
    <div class="logo">
      <img src="@/assets/images/login/logo.png" alt />
    </div>
    <!-- 左面公司 -->
    <div class="company">
      <div>
        <span>大</span>
        <span>咖</span>
        <span>招</span>
        <span>聘 </span>
        <span></span>
        <span>应</span>
        <span>聘</span>
        <span>大</span>
        <span>咖</span>
      </div>
      <div class="last">
        <span>为</span>
        <span style="font-size: 45px; color: #4d4d4d; margin: 0">中</span>
        <span>国</span>
        <span style="margin: 0px">企 </span>
        <span>业</span>
        <span>加</span>
        <span style="font-size: 45px; color: #4d4d4d; margin: 0">油</span>
      </div>
      <span class="dot1"></span>
      <span class="dot2"></span>
    </div>
    <!-- form -->
    <div class="login_form">
      <div>用户登录</div>
      <div class="ipt_wrap">
        <input
          class="ipt"
          type="text"
          placeholder="请输入帐号"
          v-model.trim="user.username"
        />
      </div>
      <div class="ipt_wrap">
        <input
          class="ipt"
          type="password"
          placeholder="请输入密码"
          v-model="user.password"
        />
      </div>
      <el-button class="enter btn" :loading="loading" @click="enterIN"
        >登&emsp;录</el-button
      >
      <div class="agreement">
        <el-checkbox v-model="checkbox">我同意</el-checkbox>
        <span @click="$router.push('/agree')">《咖聘隐私政策》</span>
        和
        <span @click="$router.push('/conceal')">《咖聘使用协议》</span>
      </div>
      <div class="reg" @click="$router.push('/register')">企业免费注册</div>
    </div>
  </div>
</template>
<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { login } from "@/api/login.js";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      //登录按钮状态
      loading: false,
      //是否同意协议
      checkbox: false,
      user: {
        username: "",
        password: "",
      },
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    enterIN() {
      if (this.loading === false) {
        this.enter();
      } else {
        return;
      }
    },
    enter() {
      console.log(this.checkbox);
      if (!this.checkbox) {
        this.$message({
          message: "请同意下方协议",
          type: "warning",
        });
      } else {
        if (this.user.username === "") {
          this.$message({
            message: "请填写帐号",
            type: "warning",
          });
          return;
        }
        if (this.user.password === "") {
          this.$message({
            message: "请填写密码",
            type: "warning",
          });
          return;
        }
        this.loading = true;
        login(this.user).then((res) => {
          if (res.code === 0) {
            this.loading = false;
            console.log(res);
            sessionStorage.setItem("token", res.data.token);
            sessionStorage.setItem("is_admin", res.data.is_admin);
            sessionStorage.setItem("isduihuan", res.data.isduihuan);
            sessionStorage.setItem("is_gl_agent", res.data.is_gl_agent);
 

            if (this.user.username == "13315528896") {
              sessionStorage.setItem("is_admin", 2);
            }

            this.$message({
              message: res.msg,
              type: "success",
            });
            if (res.data.is_setting === 0) {
              this.$router.replace("/setshow");
            } else {
              this.$router.replace("/company");
            }
          } else {
            // if (res.msg.username || res.code === 202002) {
            //   var message = '帐号输入有误，请重新填写'
            //   this.loading = false
            // } else if (res.msg.password || res.code === 202005) {
            //   var message = '密码输入有误，请重新填写'
            //   this.loading = false
            // } else if (res.msg.username && res.msg.password) {
            //   var message = '输入有误，请重新填写'
            //   this.loading = false
            // }
            this.loading = false;
            this.$message({
              message: res.msg,
              type: "error",
            });
          }
        });
      }
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped lang="scss">
/* //@import url(); 引入公共css类 */
.wrap {
  width: 1200px;
  margin: 0 auto;
  height: 100%;
  padding-top: 100px;
  box-sizing: border-box;
  /* background-color: red; */
}
/* 解决高度塌陷 */
.wrap::after {
  content: "";
  display: block;
  visibility: hidden;
  height: 0;
  clear: both;
}
.company {
  /* background-color: #f4f4f4; */
  float: left;
  position: relative;
  left: 20px;
  padding: 200px 0;
}
.logo {
  width: 100px;
  /* height: 50px; */
}
.logo img {
  width: 100%;
  height: 100%;
}
/* .company .dot1 {
  display: inline-block;
  position: absolute;
  z-index: 200;
  left: -50px;
  top: 30px;
  width: 300px;
  height: 100px;
  background-image: url('~@/assets/images/login/dot.png');
  background-repeat: no-repeat;
  background-size: contain;
} */
/* .company .dot2 {
  display: inline-block;
  position: absolute;
  z-index: 200;
  right: 50px;
  top: 430px;
  width: 300px;
  height: 100px;
  background-image: url('~@/assets/images/login/dot.png');
  transform: rotate(90deg);
  background-repeat: no-repeat;
  background-size: cover;
} */
.company > div {
  width: 700px;
  margin: 0;
  font-size: 41px;
  color: #4d4d4d;
  cursor: pointer;
  margin-bottom: 40px;
  overflow: hidden;
}
.company > .last {
  font-size: 45px;
  font-weight: 600;
}
.company > .last span {
  width: 56px;
}
/* 划过让字体变大 */
.company > div span {
  display: block;
  float: left;
  text-align: center;
  width: 60px;
}
.company div span:nth-child(4) {
  margin-right: 16px;
}
.company div span:nth-child(2) {
  color: #e59545;
  font-size: 50px;
  font-weight: 600;
  margin-top: -12px;
}
.company div span:nth-child(7) {
  color: #e59545;
  font-size: 50px;
  font-weight: 600;
  margin-top: -12px;
}
/* .company div span:hover {
  font-size: 60px;
  color: #ff8f00;
} */
.login_form {
  float: right;
  width: 500px;
  /* height: 560px; */
  /* height: 50%; */
  box-sizing: border-box;
  padding: 90px 40px 30px;
  text-align: center;
  background-color: #fff;
  box-sizing: border-box;
  border-radius: 10px;
}
.login_form div:nth-child(1) {
  font-size: 23px;
  color: #666;
  text-align: center;
  margin-bottom: 46px;
}
.ipt_wrap {
  padding-left: 50%;
}
.ipt {
  transform: translate(-50%);
  display: block;
  margin-top: 40px;
  height: 47px;
  width: 280px;
  box-shadow: inset 0 24px 64px 0 rgba(14, 20, 33, 0.1);
  border-radius: 5px;
  text-indent: 2rem;
  font-size: 17px;
  color: #666;
}
.agreement {
  margin-bottom: 20px;
  font-size: 14px;
  color: #666;
}
.agreement span {
  color: $main2_color;
  cursor: pointer;
  font-size: 14px;
}
.enter {
  width: 280px;
  height: 50px;
  border-radius: 5px;
  background-color: $main2_color;
  color: #fff;
  border: none;
  font-size: 18px;
  margin-top: 40px;
  margin-bottom: 60px;
}
/* .enter:hover {
  background-color: #2468f2;
} */
/* 注册 */
.reg {
  font-size: 14px;
  text-align: right;
  cursor: pointer;
  color: #666;
}
.reg:hover {
  color: $main2_color;
}

div /deep/ .el-checkbox__inner:hover {
  border-color: $main2_color;
}

div/deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
  color: $main2_color;
}
div/deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: $main2_color;
  border-color: $main2_color;
}
div/deep/ .el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: $main2_color;
}
</style>
